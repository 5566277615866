import styled from "styled-components"

export const PersonalityTestStyles = styled.div`
  margin: 65px auto;
  width: 900px;
  text-align: center;

  .header {
    position: relative;
    width: 100%;

    .title {
      width: 100%;
      height: 100%;
      font-weight: bold;
      font-size: 32px;
      color: white;
      position: absolute;
      z-index: 20;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 700px) {
        font-size: 16px;
      }
      @media (max-width: 300px) {
        font-size: 14px;
      }
    }
  }

  .hasilTesSebelumnya {
    padding: 40px 0px;
    padding-bottom: 0px;

    .judul {
      font-weight: 600;
      font-size: 24px;
      @media (max-width: 400px) {
        font-size: 16px;
      }
    }

    .result {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 80px;
      padding: 20px 0;
      @media (max-width: 700px) {
        flex-direction: column;
        gap: 25px;
      }

      img {
        width: 300px;

        @media (max-width: 400px) {
          width: 100%;
        }
      }

      resultDescription {
        flex-direction: column;
      }

      .textLink {
        text-decoration: underline;
        cursor: pointer;
        color: #efa51d;
      }
      .textLink:hover {
        font-weight: 600;
      }
    }
  }

  .mulai {
    padding: 5px 25px;
    margin-top: 20px;
    font-size: 16px;

    @media (max-width: 350px) {
      font-size: 12px;
      padding: 5px 15px;
    }
  }

  .textToLink {
    font-size: 16px;

    @media (max-width: 350px) {
      font-size: 14px;
    }
  }

  .titleResult {
    font-weight: bold;
    font-size: 20px;
    text-align: left;
  }

  .counter {
    text-align: left;
    padding: 10px 0px;
    font-weight: 500;
    font-size: 20px;

    @media (max-width: 700px) {
      font-size: 16px;
    }
  }

  .box {
    background-color: white;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    margin: 20px auto;

    .titleOnGoing {
      font-weight: bold;
      font-size: 20px;
      text-align: left;
      position: absolute;
      z-index: 20;
      color: white;
      padding: 30px;

      @media (max-width: 400px) {
        font-size: 16px;
        padding: 15px;
      }

      .buttonBack {
        margin-top: 8px;
        padding: 5px 20px;
        font-size: 16px;
        @media (max-width: 400px) {
          font-size: 12px;
          padding: 5px 10px;
        }
      }
    }

    .content {
      padding: 40px;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: pre-wrap;

      @media (max-width: 700px) {
        padding: 20px;
      }
      @media (max-width: 400px) {
        font-size: 12px;
      }
    }

    .contentResult {
      padding: 30px;
      text-align: center;

      .title {
        font-size: 24px;
        padding: 0px;
        padding-bottom: 10px;
        font-weight: bold;
        color: #efa51d;

        @media (max-width: 400px) {
          font-size: 20px;
        }
      }

      .descriptionResult {
        margin: 0px 35px;
        text-align: justify;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: pre-wrap;

        @media (max-width: 700px) {
          margin: 0px;
        }
        @media (max-width: 400px) {
          font-size: 12px;
        }
      }
    }

    .ongoingContent {
      display: flex;
      flex-direction: row;

      @media (max-width: 992px) {
        flex-direction: column;
      }
    }

    .titlePertanyaan {
      font-size: 22px;
      font-weight: 500;

      @media (max-width: 700px) {
        font-size: 18px;
      }
      @media (max-width: 400px) {
        font-size: 14px;
      }
    }

    .crop {
      position: relative;
      @media (max-width: 992px) {
        width: 100%;
        height: 200px;
        overflow: hidden;
      }
    }

    .imgOngoing {
      text-align: left;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      height: 100%;
      min-height: 580px;
      object-fit: cover;
      width: 350px;
      z-index: 10;

      @media (max-width: 992px) {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 12px;
        height: 100px;
        width: 100%;
        object-fit: cover;
      }
    }

    .choices {
      padding: 25px;
    }

    .buttonNavi {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      margin: 65px auto;
      margin-bottom: 0px;
      align-items: center;
      bottom: 0px;

      .buttonStyle {
        font-weight: 500;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        @media (max-width: 992px) {
          font-size: 14px;
          width: auto;
          margin: 70px 10px;
        }

        @media (max-width: 400px) {
          font-size: 12px;
          gap: 5px;
          padding: 10px;
          margin: 5px 10px;
        }

        img {
          width: 18px;
          @media (max-width: 400px) {
            width: 14px;
          }
        }
      }
      @media (max-width: 992px) {
        gap: 0px;
        margin-top: 30px;
        height: 100px;
      }

      @media (max-width: 400px) {
        margin-top: 10px;
      }

      @media (max-width: 300px) {
        flex-direction: column-reverse;
        margin-top: 20px;
      }
    }
  }

  .resultImage {
    width: 380px;
    padding: 10px 35px;
    @media (max-width: 400px) {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    width: auto;
    margin: 65px 20px;
  }
`
