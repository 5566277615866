import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../../../components/layout"
import * as sessionSelectors from "../../../redux/slices/session/selectors"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { navigate } from "gatsby"
import Spinner from "../../../components/Reusable/Spinner"
import { PersonalityTestStyles } from "../../../components/PersonalityTest/style"
import { getTestProgress } from "../../../services/personalityTest"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const TestResultPage = ({ user, params }) => {
  const id = params.id
  const [result, setResult] = useState()

  const login = () => {
    toast("Anda perlu login terlebih dahulu")
    setTimeout(() => {
      navigate("/login")
    }, 1000)
  }

  useEffect(async () => {
    if (user?.email) {
      await getTestProgress(id).then(resp => setResult(resp.data.result))
    } else {
      login()
    }
  }, [])

  return (
    <Layout>
      {user?.email && result ? (
        <PersonalityTestStyles>
          <div className="titleResult">
            Hasil Kuis Kepribadian John Oldham Bahasa Indonesia
          </div>
          <img src={result?.image} alt="person" className="resultImage" />
          <div className="box">
            <div className="contentResult">
              <div className="title">{result?.title}</div>
              <div
                className="descriptionResult"
                dangerouslySetInnerHTML={{
                  __html: result?.description_html,
                }}
              ></div>
            </div>
          </div>
        </PersonalityTestStyles>
      ) : (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </Layout>
  )
}

export default withConnect(TestResultPage)
